<template>

  <div class="page-content">
    <div class="container-fluid">

      <Breadcrumb
          :pgTitle="$t('nav.contact')"
          :pgIcon="'bx bx-flag'"
          :refs="refs">
      </Breadcrumb>


      <DataTable
          :refs="refs"
          :colspan=5

          :hasSearchFilter=true
          :hasDateFilter=true

          :hasHashId=true
          :hasMultiSelect=false
          :hasBulkAction=true
          :HasExport=true
          :hasShowEntries=true

          :lblSearch="$t('app.search')"

          :fields="{
                    'id': 'id',
                    'title': 'title',
                    'date': 'date',
                    'status': 'status'
                }"

          :hasName=true
          :name="$t('view.name')"

          :hasEmail=true
          :email="$t('view.email')"

          :hasSubject=true
          :subject="$t('view.subject')"

          :hasDate=true
          :hasActions=true
          :hasDeleteButton=true

          :actionable=true
          :sorDataTable=true

          v-on:hasAddNewPermission="handlePermissoinChange">
      </DataTable>

    </div>
  </div>

</template>

<script>
export default {
  name: 'List',
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    DataTable: () => import('@/components/DataTable.vue')
  },
  data() {
    return {
      //
      addNew: false,
      refs: 'contact',
    }
  },
  watch: {
    //
  },
  mounted() {
    //
  },
  created() {
    //
  },
  methods: {
    handlePermissoinChange(event) {
      //console.log(event)
      this.addNew = event;
    },
  },
}
</script>
